<template>
  <!-- <div class="news"></div> -->
  <div class="container">
    <div class="container-main">
      <div class="oneNews" v-for="(item, index) in newsList" @click="newsClick('/news/' + item.newsId)" :key="index">
        <div class="news">
          <div class="news-title">{{  item.newsTitle  }}</div>
          <div class="news-desc">{{  item.newsDescribe  }}</div>
          <!-- 新闻按钮 -->
          <div class="news-Btn">新闻</div>
        </div>
        <div class="news-date">{{  item.newsTime | dateFormat  }}</div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios'
import { getNewsListApi } from "@/request/api"
export default {
  data() {
    return {
      newsList: []
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      axios.post(getNewsListApi, { newsOrProduct: 0, }, {
      }).then(res => {
        if (res.status == 200) {
          this.newsList = res.data.data
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 点击跳到新闻详情
    newsClick(url) {
      this.$router.push({ path: url })
    }
  },
}


</script>

<style lang="less" scoped>
.container {
  background: #fff;
  min-height: 600px;

  .container-main {
    width: 1200px;
    margin: 0 auto;
    padding: 40px 0;

    .oneNews {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #D8D8D8;
      cursor: pointer;

      .news {
        width: 80%;
        padding-top: 22px;

        .news-title {
          font-size: 22px;
          font-weight: bold;
          color: #000;
        }

        .news-Btn {
          width: 52px;
          height: 25px;
          line-height: 25px;
          text-align: center;
          padding: 3px 12px;
          margin-bottom: 22px;
          border: 1px solid #C9C9C9;
          opacity: 1;
          border-radius: 6px;
          font-size: 14px;
          color: #1E50AE;
        }

        .news-desc {
          font-size: 20px;
          color: #484848;
          margin: 35px 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      .news-date {
        font-size: 18px;
        color: #3A3A3A;
      }
    }
  }

}
</style>